.form {
  position: relative;

  & .button {
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
  }
}

.text {
  display: block;
}
