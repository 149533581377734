.wrapper {
  position: relative;

  & .link {
    position: absolute;
    display: none;
    left: 20px;
    top: 20px;
    z-index: 1;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.05);

    & .link {
      display: inline-flex;
    }
  }
}
