@use '/styles/variables' as *;

.wrapper {
  max-width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px 40px;
  border: 1px solid white;
  margin: 24px auto;
  box-shadow: 0 0 25px rgb(20 165 17 / 50%), 0 0 50px rgb(20 165 17 / 10%),
    inset 0 0 25px rgb(20 165 17 / 50%), inset 0 0 50px rgb(20 165 17 / 10%);
}

.title {
  font-size: 22px;
  color: white;
  letter-spacing: 0.1em;
  margin-bottom: 24px;
  @extend %text-shadow-light-g-green-dark;
}
