@use '/styles/media' as *;

.preview-image {
  width: 100%;
  height: auto;
  min-height: 90vh;
  @include media-breakpoint-down(lg) {
    object-fit: cover;
  }
}

.hero {
  width: 100%;
  height: 659px;
  background-color: #040404;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #171819;

  @include media-breakpoint-down(xl) {
    background-size: cover !important;
  }

  @include media-breakpoint-down(lg) {
    background-image: none !important;
    height: auto;
  }

  &-container {
    width: 100%;
    max-width: 1200px;
    height: 100%;

    @include media-breakpoint-down(xl) {
      width: 100%;
    }

    @include media-breakpoint-down(lg) {
      width: 100%;
      margin: 0;
      flex-direction: column-reverse;
      align-items: center;
    }
  }

  &-content {
    width: 40%;
    height: 100%;
    flex-direction: column;
    justify-content: center;

    @include media-breakpoint-down(xl) {
      min-width: 360px;
    }

    @include media-breakpoint-down(lg) {
      width: calc(100% - 64px);
      min-width: 240px;
      max-width: 600px;
      height: auto;
      margin: 64px 32px;
      text-align: center;
      align-items: center;
    }
  }

  &-button {
    cursor: pointer;
    width: 240px;
    height: 64px;
    border-radius: 10px;
    font-size: 18px;
    line-height: 24px;
  }

  &-image {
    display: none;

    @include media-breakpoint-down(lg) {
      display: initial;
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }
}

// About block

.about {
  width: 100%;
  justify-content: center;

  &-container {
    width: 100%;
    max-width: 1200px;
    align-items: center;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin: 120px 0;

    @include media-breakpoint-down(lg) {
      grid-template-columns: 1fr;
      margin: 80px 0;
    }
  }

  &-content {
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: space-between;

    @include media-breakpoint-down(xl) {
      width: 100%;
    }

    @include media-breakpoint-down(lg) {
      align-items: center;
      text-align: center;
    }
  }

  &-card {
    display: flex;
    flex-direction: column-reverse;
    height: 580px;
    border-radius: 20px;

    padding: 24px;

    @include media-breakpoint-down(xl) {
      width: 480px;
      height: 480px;
      min-height: 480px;
      margin-left: 0;
      margin-bottom: 64px;
    }

    @include media-breakpoint-down(lg) {
      width: 100%;
      max-width: 440px;
      height: 440px;
      min-height: 440px;
      margin-left: 0;
      margin-bottom: 32px;
    }
  }
}

// Lots block

.lots {
  width: 100%;
  height: auto;
  padding: 120px 0;

  &-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
}

.video {
  position: relative;
  padding-top: 67%;

  & video {
    border-radius: 18px;
    position: absolute;
    object-fit: cover;
    height: 100%;
    width: 100%;
    inset: 0;
  }

  & .content {
    position: relative;
    padding: 2rem;
    display: grid;
    gap: 1rem;
  }
}
