@use '/styles/variables' as *;
@use '/styles/media' as *;
@use '/styles/utils' as u;

.wrapper {
  letter-spacing: $g-letter-spacing;
  font-family: $g-font-family;
  display: flex;
  flex-flow: column;
  position: relative;

  .error-message {
    min-height: map-get($spaces, xxl);
    width: 100%;
    text-align: start;
    color: transparent;
    font-size: map-get($font-sizes, sm);
    margin-top: 8px;
    &-active {
      color: color(red);
    }
  }

  .input-wrapper {
    border-radius: map-get($border-radiuses, md);
    font-size: 16px;
    line-height: 28px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    color: map-get($colors, g-gray-1);
    transition: 0.15s;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    & textarea {
      background-color: transparent;
      border: none;
      width: 100%;
      max-width: 100%;
      resize: none;
      min-height: 104px;
      padding: 10px 16px;
      outline: none;
      font-weight: normal;
      border-radius: map-get($border-radiuses, md);
      border: 1px solid color(gray);
      color: color(white);
      caret-color: $g-primary-outline;
      transition: inherit;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: color(white);
      }
    }

    &:not(.error) {
      textarea:not(:disabled) {
        &:hover,
        &:focus,
        &:focus-within {
          border-color: $g-primary-outline;
          background-color: u.g-dimmest($g-primary);

          @extend %basic-shadow-primary;
        }

        &:hover + svg,
        &:focus + svg,
        &:active + svg {
          @extend %filter-shadow-primary;
        }
      }
    }

    &.error {
      textarea {
        border-color: $g-primary-outline;
        @extend %basic-shadow-red;
      }
    }

    & textarea::placeholder {
      color: map-get($colors, g-gray-1);
    }

    & textarea:disabled {
      &::placeholder {
        color: map-get($colors, g-gray-3);
      }

      color: color(gray);

      border-color: color(gray);
      box-shadow: none;

      &:not(&:placeholder-shown) + label {
        font-size: 13px;
        color: color(gray);
        top: 0;
      }

      & ~ .label-error {
        color: color(gray) !important;
      }
    }

    &.disabled {
      color: map-get($colors, g-gray-3);
    }
  }
}
