@use '/styles/variables' as *;
@use '/styles/media' as *;

.testimonial-container {
  gap: 24px;
  max-width: 500px;
  padding: 20px;
  @include media-breakpoint-down(sm) {
    padding: 0;
  }
}

.customer-container {
  margin-top: 30px;
}

.customer-text {
  margin-left: 12px;
  display: flex;
  flex-direction: column;
}
