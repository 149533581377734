@use '/styles/variables' as *;
@use '/styles/media' as *;

.container {
  overflow: hidden;
}

.slides {
  display: grid;
  width: calc(100% * var(--items-count, 10));
  grid-template-columns: repeat(var(--items-count, 10), 1fr);
}

.slide {
  &.smooth {
    transition: transform 0.3s;
  }

  transform: translate(calc(var(--i, 0) * -100% + var(--tx, 0px)));

  @include media-breakpoint-up(lg) {
    margin: auto;
  }

  min-width: 100%;
  width: 100%;
  max-width: 100%;

  background: linear-gradient(
    100.47deg,
    rgba(255, 255, 255, 0) 0.25%,
    rgba(255, 255, 255, 0.03) 100%
  );

  border: 1px solid color(dividers);
  border-radius: 20px;
}

.control-buttons {
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    position: absolute;
    top: calc(50% - 64px);
  }

  :first-child {
    left: -72px;
  }
  :last-child {
    right: -72px;
  }

  @include media-breakpoint-down(xl) {
    display: none;
  }
}

.dots {
  display: grid;
  justify-content: center;
  padding: 27px 0;
  grid-auto-flow: column;
  gap: 24px;

  & .dot {
    height: 10px;
    width: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    cursor: pointer;
    will-change: filter;

    &-active {
      background-color: transparent;
      border: 1px solid lighten($g-primary, 20%);

      @extend %filter-shadow-primary;
    }
  }
}

@each $key, $value in $colors {
  .#{'' + $key} {
    .dot-active {
      border: 1px solid lighten($value, 25%);

      @extend %filter-shadow-#{'' + $key};
    }
  }
}
