@use '/styles/media' as *;
@use '/styles/variables' as *;

.image {
  border-radius: 1.5rem;
  width: 100%;
}

.video-wrapper {
  margin: 0 auto;
  position: relative;

  @include media-breakpoint-up(lg) {
    max-width: 75%;
  }
}

.video-aspect-ratio {
  height: 0;
  padding-bottom: 56.25%;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 1.5em;

  iframe {
    min-height: 100%;
    min-width: 100%;
  }
}

.center {
  text-align: center;
}
