@use '/styles/media' as *;

.container {
  margin-bottom: 60px;
}

.form {
  display: grid;
  width: 100%;
}

.name-email-inputs {
  gap: 20px;
  grid-auto-flow: column;

  @include media-breakpoint-down(lg) {
    gap: 0;
    grid-auto-flow: row;
  }
}
