@import '/styles/variables';
@import '/styles/media';
@import '/styles/utils';

.content {
  display: grid;
  width: 100%;

  grid-template-areas: 'image text';
  grid-template-columns: 440px auto;
  place-items: center;
  padding: 80px;
  gap: 80px;

  @include media-breakpoint-down(xl) {
    grid-template-areas: 'image' 'text';
    grid-template-columns: 100%;
    gap: 20px;
    padding: 0;
  }

  @each $key, $value in $colors {
    &.#{'' + $key} {
      .image-part {
        @extend %basic-shadow-#{$key};
      }
    }
  }
}

.image-part {
  grid-area: image;
  height: auto;
  position: relative;
  border-radius: 12px;

  img {
    object-fit: cover;
    border-radius: 12px;
    aspect-ratio: 4 / 3;
  }

  @include media-breakpoint-down(md) {
    margin-bottom: auto;
    width: 100%;
    box-shadow: unset !important;
    img {
      border-end-end-radius: 0;
      border-end-start-radius: 0;
    }
    border-end-end-radius: 0;
    border-end-start-radius: 0;
  }

  .label {
    position: absolute;
    top: 0;
    left: 0;
  }

  .sign {
    top: 0;
    left: calc(100% - 32px);
    display: grid;
    gap: 16px;
    position: absolute;
    z-index: 1;
    color: white;
    top: 24%;

    @include media-breakpoint-down(xl) {
      display: none;
    }

    & div {
      height: 5px;
      width: 64px;
      background-color: currentColor;
    }
  }
}

.text-part {
  grid-area: text;
  display: grid;
  gap: 32px;

  @include media-breakpoint-down(xl) {
    padding: 0px 20px 20px 20px;
  }

  .header {
    width: 100%;
    display: grid;
    gap: 8px;
  }

  .title {
    font-size: 56px;
    line-height: 60px;
    color: transparent;
    will-change: opacity;
    animation: blink 6s infinite alternate;

    &.turn-off-animation {
      animation: unset;
    }

    @include media-breakpoint-down(xl) {
      text-align: center;
    }

    @include media-breakpoint-down(sm) {
      line-height: 40px;
      font-size: 32px;
    }
  }

  .description {
    color: map-get($colors, g-white);
    line-height: 28px;
    font-size: 15px;

    a {
      font-size: 15px;
    }

    & > p:not(:last-child) {
      margin-bottom: 14px;
    }

    @include media-breakpoint-down(xl) {
      text-align: center;
    }
  }
}

@keyframes blink {
  40% {
    opacity: 1;
  }
  42% {
    opacity: 0.8;
  }
  43% {
    opacity: 1;
  }
  45% {
    opacity: 0.2;
  }
  46% {
    opacity: 1;
  }
}

.link-style {
  padding: 0;
  width: 100%;
  border: none;
  background-color: transparent;
  text-align: left;
  &:hover {
    cursor: pointer;
  }
}

.buttons-wrapper {
  width: 100%;
  justify-content: start;
  display: grid;
  gap: 16px;
  grid-row: 16px;
  grid-auto-flow: column;

  @include media-breakpoint-down(xl) {
    justify-content: center;
  }

  @include media-breakpoint-down(lg) {
    justify-content: stretch;
  }
  @include media-breakpoint-down(sm) {
    grid-auto-flow: unset;
  }
}
