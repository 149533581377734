@use '/styles/variables' as *;
@use '/styles/media' as *;

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px 40px;
  border: 1px solid white;
  margin: 24px auto;
  box-shadow: 0 0 25px rgb(20 165 17 / 50%), 0 0 50px rgb(20 165 17 / 10%),
    inset 0 0 25px rgb(20 165 17 / 50%), inset 0 0 50px rgb(20 165 17 / 10%);

  @each $key, $value in $colors {
    &.#{'' + $key} {
      @extend %basic-shadow-#{'' + $key};
      .title {
        @extend %text-shadow-#{'' + $key};
      }
    }
  }
}

.title {
  font-size: 22px;
  color: white;
  letter-spacing: 0.1em;
  margin-bottom: 24px;
  text-transform: uppercase;
  text-align: center;

  @extend %text-shadow-light-g-green-dark;
}
