@use '/styles/variables' as *;
@use '/styles/media' as *;

.featured-slider {
  margin: 0 auto;
  margin-top: 20px;

  @include media-breakpoint-up(lg) {
    margin-top: 80px;
    align-items: center;
  }

  .slide {
    background: linear-gradient(
      100.47deg,
      rgba(255, 255, 255, 0) 0.25%,
      rgba(255, 255, 255, 0.03) 100%
    );

    border: 1px solid color(dividers);
    border-radius: 20px;
  }
}
