@use '/styles/variables' as *;

.card {
  .link {
    cursor: pointer;
  }
  padding: 2rem;
  border-radius: 20px;
  border: 1px solid map-get($colors, dividers);
  background-color: map-get($colors, elevated);
  transition: 0.3s ease-out transform;
  height: 100%;

  &:hover {
    transform: translateY(-10px);
  }

  & .icon-container {
    margin-bottom: 75px;
  }

  & .description {
    color: map-get($colors, g-white);
    letter-spacing: 0.02em;
    font-size: 0.9375rem;
    line-height: 1.5rem;
    font-weight: 400;
    margin-top: 1rem;

    a {
      color: color(green);
      &:hover {
        @extend %text-shadow-light-green;
        transition: all 0.05s ease-in-out;
        svg {
          @extend %filter-shadow-green;

          transition: all 0.05s ease-in-out;
        }
      }
    }

    & ul {
      all: unset;
      list-style-position: inside;
    }
  }

  & .disclaimer {
    display: block;
    color: map-get($colors, light-gray);
    letter-spacing: 0.04em;
    font-size: 0.8125rem;
    margin-top: 1rem;
  }
}
