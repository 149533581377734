@import '/styles/variables';
@import '/styles/media';

.wrapper {
  padding: 50px 0px;
}

.top {
  .title {
    margin-bottom: 38px;
  }

  .sub-title {
    margin-bottom: 18px;
  }

  margin-bottom: 64px;
}

.auctions {
  display: flex;
  flex-direction: column;

  .separator {
    width: 100%;
    height: 1px;
    background-color: #232323;
    margin-top: 60px;
  }
}

.auction {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  position: relative;
  padding-top: 60px;

  @include media-breakpoint-down(lg) {
    grid-template-columns: 1fr;
  }

  & > .left {
    display: flex;
    justify-content: center;
  }

  & > .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.packages {
  margin-top: -1rem;
}

.package {
  display: flex;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .image {
    position: relative;
    display: flex;
    flex-direction: column;

    img {
      border-radius: 8px;
    }

    @include media-breakpoint-down(sm) {
      margin: 0 15px;
    }
  }

  .meta {
    padding: 14px 20px;
    background-color: white;
    transform: translateY(-50%);
    bottom: 0;
    width: 80%;
    margin-bottom: -60px;
    border-radius: 8px;

    span::selection {
      background-color: map-get($colors, green);
      color: map-get($colors, black);
    }
  }
}

.package {
  padding: 1rem 0;
  position: relative;

  display: grid;
  grid-template-columns: 100px 1fr;
  gap: 20px;
  align-items: center;

  .text {
    gap: 20px;
    width: 100%;

    @include media-breakpoint-up(lg) {
      grid-auto-flow: column;
      align-items: center;
      grid-template-columns: 2fr 1fr;
    }

    @include media-breakpoint-down(lg) {
      place-content: center;
      text-align: center;
    }

    button {
      margin: 0 auto;
    }
  }

  img {
    object-fit: contain;
  }

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: map-get($colors, g-gray-5);
    bottom: 0;
    left: 0;
    border-radius: 2px;
  }
}

.link {
  color: $g-primary;
  text-decoration: none;
  text-align: end;
  align-self: flex-end;
  font-size: 18px;

  &:hover {
    text-decoration: underline;
  }
}
